import React from 'react';

import Styled from 'styled-components';

export default function MaterialButton({ children, ...rest }) {
  return <Button {...rest}>{children}</Button>;
}

const Button = Styled.button`
  border: none;
  background: ${props => (props.bgcolor ? props.bgcolor : '#319795')};
  color: white;
  padding: 12px 24px;
  border-radius: 2px;
  margin-right: 12px;
  margin-bottom: 12px;
  margin-top: 12px;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  outline: 0;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  transition: all 0.6s ease;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    background: ${props => (props.bgcolor ? props.bgcolor : '#38B2AC')};
  }
`;
