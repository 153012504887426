import React from 'react';

export default function useErrorMessage(errors) {
  return function(name) {
    if (!errors || !name || !errors[name]) {
      return;
    }

    return (
      <p
        style={{
          borderLeft: '6px solid #E53E3E',
          padding: '12px 0 12px 12px',
          backgroundColor: '#FFF5F5',
        }}
      >
        {errors[name].message || 'There are errors with this field.'}
      </p>
    );
  };
}
