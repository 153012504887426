import React, { useState } from 'react';

import Input from '../common/elements/input/MBInput';
import Textarea from '../common/elements/textarea/MBTextarea';
import Button from '../common/elements/button/MaterialButton';
import Select from '../common/elements/select/MBSelect';

import useForm from 'react-hook-form';

import { service } from '../../util/service';

import useErrorMessage from '../../hooks/useErrorMessage';

export default function PriceQuoteForm(props) {
  const { register, handleSubmit, errors } = useForm();
  const errorMessage = useErrorMessage(errors);
  const [quoteSubmitting, setQuoteSubmitting] = useState(false);
  const [quoteSubmitted, setQuoteSubmitted] = useState(false);
  const [quoteError, setQuoteError] = useState(false);
  const [quoteSuccess, setQuoteSuccess] = useState(false);

  async function onSubmit(data) {
    setQuoteSubmitting(true);
    setQuoteError(false);

    try {
      data.source = props.source || 'Price quote form';
      const result = await service('leads').create(data);
      if (result) {
        setQuoteSubmitted(true);
      }
    } catch (e) {
      console.error(e);
      setQuoteError(true);
    } finally {
      setQuoteSubmitting(false);
      setQuoteSuccess(true);
    }
  }

  return (
    <React.Fragment>
      {quoteError && (
        <p
          style={{
            borderLeft: '6px solid #E53E3E',
            padding: '24px 0 24px 24px',
            backgroundColor: '#FFF5F5',
          }}
        >
          An error occurred while submitting this form. Please try again.
        </p>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Your name (required)"
          name="name"
          type="text"
          ref={register({
            required: { value: true, message: 'Your name is required.' },
          })}
        />
        {errorMessage('name')}
        <Input
          label="Your email address (required)"
          name="email"
          type="email"
          ref={register({
            required: {
              value: true,
              message: 'A valid email address is required.',
            },
          })}
        />
        {errorMessage('email')}
        <Input
          label="Your phone number"
          name="phoneNumber"
          type="text"
          ref={register}
        />
        <Select
          name="service"
          label="Service"
          ref={register}
          defaultValue={props.service}
          options={[
            { text: 'Carpet cleaning', value: 'carpet cleaning' },
            { text: 'Rug cleaning', value: 'rug cleaning' },
            {
              text: 'Tile and grout cleaning',
              value: 'tile and grout cleaning',
            },
            { text: 'Air duct cleaning', value: 'air duct cleaning' },
            { text: 'Upholstery cleaning', value: 'upholstery cleaning' },
            { text: 'Leather cleaning', value: 'leather cleaning' },
            { text: 'Painting', value: 'painting' },
            { text: 'Other...', value: 'other' },
          ]}
        />
        <Textarea
          label="Size and scope of the work."
          name="specifics"
          ref={register}
        />
        <Textarea
          label="Enter additional details here..."
          name="details"
          ref={register}
        />
        {quoteSubmitted && (
          <p
            style={{
              borderLeft: '6px solid #38A169',
              padding: '24px 0 24px 24px',
              backgroundColor: '#F7FAFC',
            }}
          >
            Thank you. Your request for a price quote has been submitted.
          </p>
        )}
        <Button type="submit" disabled={quoteSubmitting || quoteSuccess}>
          {quoteSuccess ? 'Request received' : 'Get your quote'}
        </Button>
      </form>
    </React.Fragment>
  );
}

PriceQuoteForm.defaultProps = {
  service: 'carpet cleaning',
};
