import React, { useState } from 'react';

import Styled from 'styled-components';

/* Material Boxed Input */
export default React.forwardRef(function MBInput(props, ref) {
  const [active, setActive] = useState(false);

  return (
    <Label
      style={{
        borderRadius: '2px',
        marginBottom: '24px',
        border: active ? '1px solid #38B2AC' : '1px solid #718096',
      }}
    >
      <span
        style={{
          transform: active ? 'translateY(-9px)' : 'none',
          fontSize: active ? '80%' : '100%',
          color: active ? '#38B2AC' : '#999',
          transition: 'all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55)',
          whiteSpace: 'nowrap',
        }}
      >
        {props.label}
      </span>
      <input
        ref={ref}
        {...props}
        placeholder=""
        onFocus={() => setActive(true)}
        style={{
          transform: 'translateY(6px)',
          outline: 'none',
          width: '100%',
        }}
        onBlur={event => event.target.value === '' && setActive(false)}
      />
    </Label>
  );
});

const Label = Styled.label`
  position: relative;
  display: block;
  padding: 0.75rem;
  margin-bottom: 12px;
  transition: all .3s ease;

  span {
    position: absolute;
    z-index: 10;
  }

  input {
    border: none;
    background: transparent;
    width: 100%;
  }
`;
