import React from 'react';

import Layout from '../layouts/mainLayout';

import PriceQuoteForm from '../components/priceQuoteForm/priceQuoteForm';

export default function PriceQuote() {
  return (
    <Layout>
      <h1>Get a price quote</h1>
      <p>Fill out the form below to receive a price quote.</p>
      <PriceQuoteForm source="Price quote page" />
    </Layout>
  );
}
