import React from 'react';

import Styled from 'styled-components';

export default React.forwardRef(function MBSelect(props, ref) {
  return (
    <Label bgcolor={props.bgcolor}>
      <span>{props.label}</span>
      <select {...props} ref={ref}>
        {props.options.map(option => (
          <option
            key={option.value}
            value={option.value}
            selected={props.defaultValue && props.defaultValue === option.value}
          >
            {option.text}
          </option>
        ))}
      </select>
    </Label>
  );
});

const Label = Styled.label`
  position: relative;
  display: block;
  border: 1px solid #718096;
  padding: 0.75rem;
  margin-bottom: 24px;
  border-radius: 2px;
  background: inherit;

  span {
    position: absolute;
    display: inline-block;
    z-index: 10;
    transform: translateY(-22px);
    font-size: 90%;
    color: #777;
    background: ${props => (props.bgcolor ? props.bgcolor : 'white')};
    padding-left: 12px;
    padding-right: 12px;
    white-space: nowrap;
  }

  select {
    background: transparent;
    border: none;
    width: 100%;
    outline: none;
  }
`;
