import Axios from 'axios';

import config from '../config';

const serviceNameToUrlMap = {
  leads: `${config.backendServer}/leads`,
  'employment-applications': `${config.backendServer}/employment-applications`,
};

export function service(serviceName) {
  return {
    find() {},
    get() {},
    create(data) {
      return Axios.post(serviceNameToUrlMap[serviceName], data);
    },
    update() {},
    remove() {},
  };
}
