import React, { useState } from 'react';

import Styled from 'styled-components';

export default React.forwardRef(function MBTextarea(props, ref) {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState(0);

  return (
    <Label
      style={{
        marginBottom: '24px',
        transition: 'all 0.3s ease',
        overflowX: 'hidden',
        border: active ? '1px solid #38B2AC' : '1px solid #718096',
      }}
    >
      <span
        style={{
          transform: active ? 'translateY(-9px)' : 'none',
          fontSize: active ? '80%' : '100%',
          color: active ? '#38B2AC' : '#999',
          transition: 'all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55)',
          whiteSpace: 'nowrap',
        }}
      >
        {props.label}
      </span>
      <textarea
        {...props}
        ref={ref}
        onFocus={() => setActive(true)}
        style={{
          transform: 'translateY(6px)',
          outline: 'none',
          width: '100%',
          boxSizing: 'border-box',
          height: height > 0 ? `${height}px` : 'auto',
          maxHeight: '240px',
          transition: 'all 0.3s ease',
        }}
        onBlur={event => event.target.value === '' && setActive(false)}
        onKeyUp={event => setHeight(event.target.scrollHeight)}
      ></textarea>
    </Label>
  );
});

const Label = Styled.label`
  position: relative;
  display: block;
  border: 1px solid #718096;
  padding: 0.75rem;
  margin-bottom: 12px;

  span {
    position: absolute;
    z-index: 10;
  }

  textarea {
    border: none;
    resize: none;
  }
`;
